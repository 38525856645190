[data-id=causes_spinner], #location_spinner {
  display: none;
}

.location-controls {
  display: none;
}

[data-action="change-location"] {
  display: none;
}

.donations-year-download-form {
  display: flex;
  justify-content: flex-end;
  allign-items: center;
  color: #999999;
}

.download-csv {
  border-radius: 25px;
  color: #999999;
}

.donation-year-select {
  border-style: none !important;
  margin-right: 5px;
  padding: 5px;
}


.donation-label-danger{
    color: #f04124;
    font-weight: bolder;
}
