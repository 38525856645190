@use "~bootstrap/scss/bootstrap";
@use "../src/online_giving/scss/pushy.scss";
@use "../src/online_giving/scss/_variables";
@use "../src/online_giving/scss/donations";
@use "../src/online_giving/scss/payment_method";
@use "../src/online_giving/scss/ladda-themeless.min";

$bg_color_picker: #f4f4f4;

// Simonwep/pickr
@use '@simonwep/pickr/dist/themes/classic.min';   // 'classic' theme

body { 
  padding-top: 115px; // dodge navbar-fixed-top
  position: relative; 
  min-height: 100vh;
}

.site-header {
    background: #fff;
    position: relative;
    top: -112px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 999px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin: 0;
}

#site-header-container {
    text-align: center;
}

.clear_both {
   clear: both !important;
}

#organization_title {
  padding: 6px;
  font-size: 4em;
  font-weight: bolder;
}

#page_container{
    text-align: center;
    top: 0;
    margin: auto;
    width: 50%;
    padding:5px;
    padding-bottom: 5rem;
}


#color_picker{
  top: 19px;
  left: 20px;
  position: relative;
  width: 200px;
  height: 200px;
  padding: 15px;
  margin: 15px;
  background: $bg_color_picker;
  margin: 0 5px
}

// .info-well-sm {
//   @extend .bg-info;
//   @extend .well;
//   @extend .well-sm;
//   color: #fff;
//   background-color: #5bc0de !important;
// }

#menu_button {
  @extend .pull-left !optional;
  position: fixed;
  top: -10px;
  left: 15px;
  cursor: pointer;
  z-index: 4000;
  font-size: 16px;
  margin-top: 21px;
  font-weight: bolder;
}

nav.pushy ul {
  list-style-type: none;
  padding-left: 0;
}

ul#verification_progress {
  list-style-type: none;
}

div#ppec_donation_description {
  margin: 20px;
}

label {
  width: 100%;
  @extend .text-left !optional;
}

label#account_authorization_label {
  margin-left: 18px;
}

input#account_authorization {
  margin-left: -18px;
}

.header-top-hidden {
  visibility: hidden;
}

#public_facing_contact_info {
  font-size: smaller;
  margin: 15px 0;

  .info {
    display: inline-block;
    margin-right: 20px;

    a {
      color: inherit;
    }

    .fa {
      margin-right: 3px;
    }
  }
}

@media screen and (max-width: 500px) {
  .navbar-fixed-top {
    position: absolute;
  }

  #org_name {
    margin-top: 40px;
  }

  // Subtract 20px from the content so we can offset the amount of space
  // the #org_name pushes it down.
  #content {
    margin-top: 20px;
  }
}

.requiredField {
    background-color: #fcc5c5;
}

#feedback {
  z-index: -1;
  display: block;
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.cc_messages {
    list-style-type: none;
    width: 100%;
    min-height: 100px;
    display: block;
    padding-left:0px;
    li.error_feedback {
        padding: 1em;
        text-align: center;
        background-color: #fada23;
    }

    li.ok_feedback {
        padding: 1px;
        color:  #383835;
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        width: 100%;
        background-color: #fff;
        background-image: url("../src/online_giving/img/fireworks_transparent_1.gif");
    }

    li a {
        text-decoration: none;
    }
}


#verified_check {
  display: none;
}

.g-recaptcha > div {
  width: auto !important;
}

footer{
  position: absolute; 
  bottom: 0; 
  width: 100%;

  @media only screen and (max-width: 600px) {
      position: relative;
  }
}

#paypal-warning {
    display: flex;
    flex-direction: column;
    align-content: center;
    color: red;
    color: black;
    font-weight: 700;
    border: solid 1px black;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
}

