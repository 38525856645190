#payment_info {
  [data-toggle="plaid"], [data-toggle="non-plaid"] {
    display: none;
  }
}

[data-apple-pay-hidden] {
  display: none;
}

#payment_options {
  display: none;

  .btn-apple-pay {
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-style: black;
  }

  .btn-payment {
    display: none;
    border-color: black;
    border-radius: 5px;
    width: 155px;
    margin: 7px;

    &[data-available='true'] {
      display: inline-block;
    }
  }
}

#bank_account_fields #selected_bank_account {
  .btn-bank-account-select, .btn-bank-account-change {
    @extend .btn !optional;

    @extend .btn-default !optional;

    @extend .btn-sm !optional;

    float: left;
  }

  .btn-bank-account-change {
    display: none;
  }

  #bank_account_name {
    font-size: 12px;
    padding-top: 9px;
    margin-right: 5px;
    float: left;
  }
}
